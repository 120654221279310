import { Form, Formik } from "formik";
import { versionValidationSchema } from "./schemas/VersionValidationSchema";
import { usePlugin } from "./contexts/PluginContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { PluginVersionExtended } from "./models/PluginVersionExtended";
import { ReactNode } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PluginVersion } from "./models/PluginVersion";
import { markdownStyle } from "../common/styles/markdownStyle";

type VersionDetailsFormProps = { version?: PluginVersionExtended; children: ReactNode };

export const VersionDetailsForm = ({ version, children }: VersionDetailsFormProps) => {
  const { pluginDetails: plugin } = usePlugin();
  const { user } = useAuth0<ExtendedUser>();
  const { translate } = useLocalization();
  const selectedAccount = user?.user_metadata?.selectedAccount;

  const initialValues: PluginVersion = {
    versionId: version?.versionId ?? -1,
    versionNumber: version?.versionNumber ?? "",
    versionUpload: null,
    downloadUrl: version?.downloadDetails?.downloadUrl ?? "",
    minimumRequiredVersionOfStudio: version?.minimumRequiredVersionOfStudio ?? "",
    maximumRequiredVersionOfStudio: version?.maximumRequiredVersionOfStudio ?? "",
    fileHash: version?.fileHash ?? "",
    markdownChangelog: version?.markdownChangelog ?? "No related information",
    htmlChangelog: version?.htmlChangelog ?? "<p>No related information<p>" + markdownStyle,
    supportedProducts: version?.supportedProducts ?? [],
    publishToRWSAppStore: version?.publishToRWSAppStore ?? selectedAccount?.publishToRWSAppStore ?? false,
    publishToPrivateAppStore: version?.publishToPrivateAppStore ?? selectedAccount?.publishToPrivateAppStore ?? false,
    isNavigationLink: version?.isNavigationLink ?? plugin?.isLanguageCloudPlugin,
    validateVersionUpload: !plugin?.isLanguageCloudPlugin && !version?.isNavigationLink,
    versionUploadRequired: !version?.downloadDetails?.downloadUrl,
    validateVersionBoundary: plugin?.hasStudioVersionSupport,
    changelogResources: version?.changelogResources ?? [],
    publishedAt: version?.publishedAt ?? "1970-01-01Z00:00:00:000",
    resourcesModified: false,
    initialComment: ""
  };

  return (
    <Formik<PluginVersion>
      validationSchema={versionValidationSchema(translate)}
      initialValues={initialValues}
      onSubmit={() => undefined}
      enableReinitialize
      validateOnChange
    >
      <Form>{children}</Form>
    </Formik>
  );
};
