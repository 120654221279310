import { useFormikContext } from "formik";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { versionValidationSchema } from "../schemas/VersionValidationSchema";
import { PluginVersion } from "../models/PluginVersion";
import { usePlugin } from "../contexts/PluginContext";
import { useState } from "react";
import { useVersionActions } from "./useVersionActions";
import { useParams } from "react-router-dom";
import { draftVersionValidationSchema } from "../schemas/DraftVersionValidationSchema";
import { processMarkdownToHTML } from "../../../utils";
import { Action } from "../models/Action";

export const useDraftVersionButtonGroup = () => {
  const [showModal, setShowModal] = useState(false);
  const { translate } = useLocalization();
  const { values, setTouched, handleSubmit: submit } = useFormikContext<PluginVersion>();
  const { pluginDetails: plugin } = usePlugin();
  const { versionId } = useParams();
  const { isLoading, versionActions } = useVersionActions();

  const handleSave = async () => {
    try {
      values.htmlChangelog = await processMarkdownToHTML(values.markdownChangelog, values.changelogResources);
      await versionValidationSchema(translate).validate(values, { abortEarly: false });
      if (values.publishToRWSAppStore && plugin?.hasMissingInformation) setShowModal(true);
      else {
        setTouched({});
        if (versionId) await versionActions.handleUpdate(parseInt(versionId), values, Action.SaveWithCloneRemoval);
        else await versionActions.handleCreate(values);
      }
    } catch (errors) {
      submit();
    }
  };

  const handleSubmit = async () => {
    try {
      values.htmlChangelog = await processMarkdownToHTML(values.markdownChangelog, values.changelogResources);
      await versionValidationSchema(translate).validate(values, { abortEarly: false });
      if (values.publishToRWSAppStore && plugin?.hasMissingInformation) setShowModal(true);
      else {
        setTouched({});
        if (versionId) await versionActions.handleUpdatePending(parseInt(versionId), values);
        else await versionActions.handleCreatePending(values);
      }
    } catch (errors) {
      submit();
    }
  };

  const handleSaveAsDraft = async () => {
    try {
      values.htmlChangelog = await processMarkdownToHTML(values.markdownChangelog, values.changelogResources);
      await draftVersionValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (versionId) await versionActions.handleUpdateDraft(parseInt(versionId), values);
      else await versionActions.handleCreateDraft(values);
    } catch (errors) {
      setTouched({ versionNumber: true, supportedProducts: true });
    }
  };

  return {
    isLoading,
    showModal,
    actions: {
      handleSaveAsDraft,
      handleSubmit,
      handleSave,
      setShowModal
    }
  };
};
