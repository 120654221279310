import { Button, Col, OverlayTrigger, Image, Row, Tooltip } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { NotificationStatus } from "./models/NotificationStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notificationStatusClassMap, notificationStatusIconMap } from "./models/NotificationStatusHelper";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { PushNotification } from "./models/PushNotification";

type NotificationDetailsProps = {
  notification: PushNotification;
  deleteNotifications: (notificationId: string) => void;
};

export const NotificationDetails = ({ notification, deleteNotifications }: NotificationDetailsProps) => {
  const { translate } = useLocalization();
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <Row className="my-2">
      <Col xs={3} className="d-flex flex-column align-items-center my-2" title={notification.title}>
        <Image width={75} src={notification.imageSource ?? `${baseUrl}/plugin.png`} alt={notification.title} />
        <p className="m-0 mt-2 fw-bold text-truncate" style={{ width: 90 }}>
          {notification.title}
        </p>
      </Col>
      <Col xs={9} className="border-start d-flex flex-column justify-content-between my-2">
        <div>{notification.message}</div>
        {notification.rejectionReason && (
          <div>
            <p className="m-0 text-wrap">
              <strong>{translate("notification.reason")}</strong>
              {notification.rejectionReason}
            </p>
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <OverlayTrigger overlay={<Tooltip>{translate("notification.view")}</Tooltip>}>
              <Button
                variant="transparent"
                className="me-2 p-0"
                aria-label={translate("notification.view")}
                onClick={() => (window.location.href = notification.callToActionUrl)}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>{translate("common.delete")}</Tooltip>}>
              <Button
                variant="transparent"
                className="p-0"
                aria-label={translate("common.delete")}
                onClick={() => deleteNotifications(notification.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="text-danger" />
              </Button>
            </OverlayTrigger>
          </div>
          <div>
            <p className="m-0" style={{ fontSize: 12 }}>
              {`${new Date(notification.createdAt as Date).toDateString()} - ${new Date(
                notification.createdAt as Date
              ).toLocaleTimeString()}`}
            </p>
            {notification.status !== NotificationStatus.InReview && (
              <p className="m-0" style={{ fontSize: 12 }}>
                {translate("notification.status")}
                {NotificationStatus[notification.status]}
                <FontAwesomeIcon
                  className={`${notificationStatusClassMap.get(notification.status)} ms-1`}
                  icon={notificationStatusIconMap.get(notification.status) as IconProp}
                />
              </p>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};
