import DOMPurify from "dompurify";
import { Resource, sampleResources } from "./components/plugins/models/Resource";
import { TranslateFunction } from "./contexts/LocalizationContext";
import { marked } from "marked";
import { markdownStyle } from "./components/common/styles/markdownStyle";

export const stringifyReplacer = (_: string, value: any) => (value === "" ? null : value);

export const getFileNameFromPath = (path: string) => {
  if (!path) return;

  let fileName = path.split("/").pop() ?? "";
  const queryIndex = fileName.indexOf("?");

  fileName = queryIndex > 0 ? fileName.substring(0, queryIndex) : fileName;

  return decodeURIComponent(fileName);
};

export const toFormData = <T extends object>(data: T): FormData => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => toFormDataPair(key, value, formData));
  return formData;
};

const toFormDataPair = (key: string, value: any, formData: FormData) => {
  if (!value) return;

  if (value instanceof File) {
    formData.append(key, value);
  } else if (Array.isArray(value)) {
    value.forEach((x) => toFormDataPair(`${key}[]`, x, formData));
  } else if (typeof value === "object") {
    for (const tuple of Object.entries(value)) {
      toFormDataPair(`${key}.${tuple[0]}`, tuple[1], formData);
    }
  } else {
    formData.append(key, `${value}`);
  }
};

export const removeHTMLTags = (input: any) => {
  const pattern = /<.*?>/gm;
  return input?.replace(pattern, "");
};

export const dateFormat = (translate: TranslateFunction, date?: Date) => {
  return date ? new Date(date).toISOString().slice(0, 10) : translate("common.unknown");
};

export const sequencesEqual = <T>(first: T[], second: T[]) => {
  const firstSet = new Set(first);
  const secondSet = new Set(second);

  return !Array.from(firstSet.values()).some((x) => !secondSet.has(x));
};

const replaceResourceNameWithUrl = (markdown: string, resources: Resource[]) => {
  return resources.concat(sampleResources).reduce((updatedMarkdown, resource) => {
    const encodedUrl = encodeURI(resource.downloadUrl);
    return updatedMarkdown.replace(resource.name, encodedUrl);
  }, markdown);
};

const generateTableOfContents = (markdown: string): string => {
  try {
    if (!/\[toc\]/i.test(markdown)) {
      return markdown;
    }

    const lines = markdown?.split("\n");
    const tocLines: string[] = [];
    const contentLines: string[] = [];

    lines.forEach((line) => {
      const headerMatch = line.match(/^(#{1,6}) (.+)/);
      if (headerMatch) {
        const level = headerMatch[1].length;
        const title = headerMatch[2];
        const anchor = title
          .toLowerCase()
          .replace(/[^\w\s-]/g, "")
          .replace(/\s+/g, "-");

        tocLines.push(`${"  ".repeat(level - 1)}- [${title}](#${anchor})`);

        contentLines.push(`${headerMatch[1]} <a id="${anchor}"></a>${title}`);
      } else {
        contentLines.push(line);
      }
    });

    const tocContent = `## Table of Contents\n\n${tocLines.join("\n")}`;
    const content = contentLines.join("\n");

    return content.replace(/\[toc\]/gi, tocContent);
  } catch (error) {
    return markdown;
  }
};

export const processMarkdownToHTML = async (markdown: string, resources: Resource[]) => {
  const markdownWithResourceUrls = replaceResourceNameWithUrl(markdown, resources);
  const markdownWithToc = generateTableOfContents(markdownWithResourceUrls);
  const sanitizedMarkdown = DOMPurify.sanitize(markdownWithToc);
  return (await marked(sanitizedMarkdown)) + markdownStyle;
};

export const getExistingResourcesSize = (resources: Resource[]) => {
  return resources.reduce((acc, resource) => acc + resource.size, 0) ?? 0;
};

export const validateResourcesTypeAndSize = (files: File[], existingResourcesSize: number) => {
  const fileExtensions = [".md", ".avif", ".gif", ".jpg", ".jfif", ".pjpeg", ".pfp", ".png", ".zip", ".rar", ".7z"];
  const isValidType = files.every(({ name }) => fileExtensions.includes(name.substring(name.lastIndexOf("."))));
  const isValidSize = existingResourcesSize + files.reduce((acc, file) => acc + file.size, 0) <= 20 * 1024 * 1024;

  if (isValidType && isValidSize) {
    return { isValid: true, message: "" };
  } else if (!isValidType) {
    return { isValid: false, message: "validation.invalidFileFormat" };
  } else {
    return { isValid: false, message: "validation.storageSpaceExceeded" };
  }
};
