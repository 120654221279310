import { Col, Row } from "react-bootstrap";
import { DraftVersionButtonGroup } from "../../components/plugins/DraftVersionButtonGroup";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { VersionDetailsForm } from "../../components/plugins/VersionDetailsForm";
import { VersionDetailsPartial } from "../../components/plugins/VersionDetailsPartial";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { Loader } from "../../components/common/progress/Loader";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useState } from "react";
import { NavigationTabButton } from "../../components/plugins/NavigationTabButton";

export const CreateVersion = () => {
  const { pluginDetails: plugin, isLoading } = usePlugin();
  const { translate } = useLocalization();
  const [section, setSection] = useState<string>("create");

  if (isLoading) return <Loader />;

  return (
    <VersionDetailsForm>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar plugin={plugin} />
        </Col>
        <Col xs={12} lg={8}>
          <ul className="nav nav-tabs position-relative mb-3">
            <NavigationTabButton
              isSelected={section === "create"}
              onClick={() => setSection("create")}
              name={translate("plugins.create")}
            />
            <NavigationTabButton
              isSelected={section === "comment"}
              onClick={() => setSection("comment")}
              name={translate("plugins.comment")}
            />
          </ul>
          <VersionDetailsPartial enableFileUpload displaySection={section} />
          {section === "create" && (
            <div className="mb-2">
              <DraftVersionButtonGroup />
            </div>
          )}
        </Col>
      </Row>
    </VersionDetailsForm>
  );
};
