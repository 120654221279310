export type Resource = {
  id: number;
  name: string;
  downloadUrl: string;
  size: number;
};

export const sampleResources: Resource[] = [
  {
    id: 0,
    name: "sample_image.png",
    size: 0,
    downloadUrl: "https://rws-prod-appstore-resources-eu-central-1.s3.amazonaws.com/sample_image.png"
  },
  {
    id: 0,
    name: "sample_settings.png",
    size: 0,
    downloadUrl: "https://rws-prod-appstore-resources-eu-central-1.s3.amazonaws.com/sample_settings.png"
  }
];
