import { useAuth0 } from "@auth0/auth0-react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, HTMLAttributes, useEffect, useState } from "react";
import { useConfig } from "../../contexts/ConfigContext";
import { useFetch } from "../../hooks/useFetch";
import { Button } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { apiRoutes } from "../../apiRoutes";
import { ExtendedUser } from "../identity/models/ExtendedUser";

type NotificationsTogglerProps = HTMLAttributes<HTMLElement> & { isAdministratorView?: boolean };

const Component = ({ isAdministratorView, ...props }: NotificationsTogglerProps, ref: React.Ref<HTMLButtonElement>) => {
  const [count, setCount] = useState(0);
  const { configuration } = useConfig();
  const { user } = useAuth0<ExtendedUser>();
  const { request } = useFetch();
  const { translate } = useLocalization();
  const userId = user?.user_metadata?.id;
  const accountId = user?.user_metadata?.selectedAccount?.id;

  useEffect(() => {
    const getCount = async () => {
      if (accountId && userId) {
        const route = isAdministratorView
          ? apiRoutes.notifications.getUserExtensiveNotificationsCount(userId)
          : apiRoutes.notifications.getUserNotificationsCount(accountId, userId);
        await request({ route, onSuccess: async (response) => setCount(await response.json()) });
      }
    };

    getCount();
  }, [configuration.apiUrl, userId, accountId, request]);

  return (
    <Button
      {...props}
      ref={ref}
      variant="transparent"
      className="position-relative border-0 p-0 text-white"
      aria-label={translate("settings.notifications")}
    >
      <FontAwesomeIcon icon={faBell} className="cursor-pointer" />
      {count > 0 && <div style={notificationsCountStyle}>{count}</div>}
    </Button>
  );
};

export const NotificationsToggler = forwardRef(Component);

const notificationsCountStyle: React.CSSProperties = {
  top: -15,
  right: -15,
  backgroundColor: "red",
  borderRadius: "50%",
  width: 20,
  height: 20,
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
