import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Loader } from "../common/progress/Loader";
import { Multiselect } from "../common/inputs/Multiselect";
import { Col, Form } from "react-bootstrap";
import { UploadFileArea } from "./UploadFileArea";
import { EditableFieldButton } from "../plugins/EditableFieldButton";
import { usePlugin } from "./contexts/PluginContext";
import { useVersion } from "./contexts/VersionContext";
import { Switch } from "../common/inputs/Switch";
import { usePreserve } from "../../contexts/PreserveContext";
import { useVersionDetailsPartial } from "./hooks/useVersionDetailsPartial";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { MarkdownEditor } from "../common/inputs/MarkdownEditor";
import { useLocation, useParams } from "react-router-dom";
import { PluginVersion } from "./models/PluginVersion";
import { publishedStatusMap } from "./models/StatusHelper";
import { routes } from "../../main/routes";
import { Editor } from "../common/inputs/Editor";

type VersionDetailsPartialProps = {
  hasLockedFields?: boolean;
  enableFileUpload?: boolean;
  displaySection?: string;
};

export const VersionDetailsPartial = ({
  enableFileUpload,
  hasLockedFields,
  displaySection
}: VersionDetailsPartialProps) => {
  const pluginContext = usePlugin();
  const [isLoading, setIsLoading] = useState(false);
  const { version } = useVersion();
  const { versionId, pluginId } = useParams() as { versionId?: string; pluginId: string };
  const { setCurrentState, setInitialState } = usePreserve();
  const { errors, touched, values, initialValues, handleChange, setFieldValue } = useFormikContext<PluginVersion>();
  const { productDetails, pluginDetails: plugin, manifestResult } = pluginContext;
  const { setManifestResultField } = pluginContext;
  const { disabledFields, actions } = useVersionDetailsPartial(!!hasLockedFields);
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const isNewVersionRoute = routes.versions.create(plugin.id) == useLocation().pathname;

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (values.initialComment === "<p><br></p>") setFieldValue("initialComment", "");
  }, [values.initialComment]);

  if (isLoading) return <Loader />;

  return displaySection === "comment" ? (
    <Editor name="initialComment" value={values.initialComment} />
  ) : (
    <>
      {enableFileUpload && <UploadFileArea setIsLoading={(value) => setIsLoading(value)} />}
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <Form.Label htmlFor="versionNumber" className="m-0">
            {translate("versions.versionNumber")}
          </Form.Label>
          <span className="text-danger ms-1">*</span>
        </Col>
        <Col xs={8}>
          <Form.Control
            type="text"
            id="versionNumber"
            name="versionNumber"
            onChange={handleChange}
            onBlur={() => setManifestResultField("IsVersionNumberMatch", true)}
            value={values?.versionNumber}
            className="w-100"
            disabled={disabledFields.versionNumber}
          />
          {touched.versionNumber && !!errors.versionNumber && (
            <span className="invalid-feedback d-inline">{errors.versionNumber}</span>
          )}
          {manifestResult["IsVersionNumberMatch"] === false && (
            <span className="invalid-feedback d-inline">{translate("versions.invalidVersionNumber")}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="versionNumber"
            isDisabled={disabledFields.versionNumber}
            onClick={() => actions.handleFieldDisable("versionNumber")}
          />
        )}
      </Form.Group>
      {getIn(values, "validateVersionBoundary") && (
        <>
          <Form.Group className="d-flex mb-3 align-items-center position-relative">
            <Col xs={4}>
              <Form.Label htmlFor="minimumRequiredVersionOfStudio" className="m-0">
                {translate("versions.minimumStudioVersion")}
              </Form.Label>
              <span className="text-danger ms-1">*</span>
            </Col>
            <Col xs={8}>
              <Form.Control
                type="text"
                id="minimumRequiredVersionOfStudio"
                name="minimumRequiredVersionOfStudio"
                onChange={handleChange}
                onBlur={() => setManifestResultField("IsMinVersionMatch", true)}
                value={values?.minimumRequiredVersionOfStudio}
                disabled={disabledFields.minimumStudioVersion}
                className="w-100"
              />
              {touched.minimumRequiredVersionOfStudio && !!errors.minimumRequiredVersionOfStudio && (
                <span className="invalid-feedback d-inline">{errors.minimumRequiredVersionOfStudio}</span>
              )}
              {manifestResult["IsMinVersionMatch"] === false && (
                <span className="invalid-feedback d-inline">{translate("versions.invalidMinimumVersion")}</span>
              )}
            </Col>
            {hasLockedFields && isSystemAdmin && (
              <EditableFieldButton
                name="minimumStudioVersion"
                isDisabled={disabledFields.minimumStudioVersion}
                onClick={() => actions.handleFieldDisable("minimumStudioVersion")}
              />
            )}
          </Form.Group>
          <Form.Group className="d-flex mb-3 align-items-center position-relative">
            <Col xs={4}>
              <Form.Label htmlFor="maximumRequiredVersionOfStudio" className="m-0">
                {translate("versions.maximumStudioVersion")}
              </Form.Label>
              <span className="text-danger ms-1">*</span>
            </Col>
            <Col xs={8}>
              <Form.Control
                type="text"
                id="maximumRequiredVersionOfStudio"
                name="maximumRequiredVersionOfStudio"
                className="w-100"
                onChange={handleChange}
                onBlur={() => setManifestResultField("IsMaxVersionMatch", true)}
                value={values?.maximumRequiredVersionOfStudio}
                disabled={disabledFields.maximumStudioVersion}
              />
              {touched.maximumRequiredVersionOfStudio && !!errors.maximumRequiredVersionOfStudio && (
                <span className="invalid-feedback d-inline">{errors.maximumRequiredVersionOfStudio}</span>
              )}
              {manifestResult["IsMaxVersionMatch"] === false && (
                <span className="invalid-feedback d-inline">{translate("versions.invalidMaximumersion")}</span>
              )}
            </Col>
            {hasLockedFields && isSystemAdmin && (
              <EditableFieldButton
                name="maximumStudioVersion"
                isDisabled={disabledFields.maximumStudioVersion}
                onClick={() => actions.handleFieldDisable("maximumStudioVersion")}
              />
            )}
          </Form.Group>
        </>
      )}
      {!enableFileUpload && (plugin.isLanguageCloudPlugin || version.isNavigationLink) && (
        <Form.Group className="d-flex mb-3 align-items-center">
          <Col xs={4}>
            <Form.Label htmlFor="downloadUrl" className="m-0">
              {translate("versions.publishedURL")}
            </Form.Label>
            <span className="text-danger ms-1">*</span>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              id="downloadUrl"
              name="downloadUrl"
              onChange={handleChange}
              value={values?.downloadUrl}
              className="w-100"
            />
            {touched.downloadUrl && !!errors.downloadUrl && (
              <span className="invalid-feedback d-inline">{errors.downloadUrl}</span>
            )}
          </Col>
        </Form.Group>
      )}

      {!!getIn(values, "validateVersionUpload") && (
        <Form.Group className="d-flex mb-3 align-items-center">
          <Col xs={4}>
            <Form.Label htmlFor="filehash" className="m-0">
              {translate("versions.checksum")}
            </Form.Label>
            <span className="text-danger ms-1">*</span>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              id="fileHash"
              name="fileHash"
              onChange={handleChange}
              value={values?.fileHash}
              className="w-100"
            />
            {touched.fileHash && !!errors.fileHash && (
              <span className="invalid-feedback d-inline">{errors.fileHash}</span>
            )}
          </Col>
        </Form.Group>
      )}
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <p className="m-0">{translate("versions.changelog")}</p>
        </Col>
        <Col xs={8}>
          <MarkdownEditor
            name="markdownChangelog"
            onPreview={(value) => setFieldValue("htmlChangelog", value)}
            onDelete={(value) => actions.deletePluginVersionResource(value)}
            onUpload={(value) => actions.savePluginVersionResource(value)}
            onChange={(value) => setFieldValue("markdownChangelog", value)}
            downloadResource={
              versionId ? () => actions.handleChangelogDownload(parseInt(pluginId), parseInt(versionId)) : undefined
            }
            markdown={values.markdownChangelog}
            html={values.htmlChangelog}
            resources={values.changelogResources}
            disableUploadTab={isNewVersionRoute}
          />
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4} className="d-flex">
          <p className="m-0">{translate("versions.productVersions")}</p>
          <span className="text-danger ms-1">*</span>
        </Col>
        <Col xs={8}>
          <Multiselect
            name="supportedProducts"
            items={productDetails.map((x) => ({ value: `${x.id}`, text: x.productName }))}
            values={values.supportedProducts.map((x) => x.toString())}
            size="lg"
            disabled={disabledFields.supportedProducts}
            onChange={(values) => {
              setFieldValue("supportedProducts", values);
              setManifestResultField("IsSupportedProductsMatch", true);
            }}
          />
          {touched.supportedProducts && !!errors.supportedProducts && (
            <span className="invalid-feedback d-inline">{errors.supportedProducts}</span>
          )}
          {manifestResult["IsSupportedProductsMatch"] === false && (
            <span className="invalid-feedback d-inline">{translate("plugins.invalidProductVersions")}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="supportedProducts"
            isDisabled={disabledFields.supportedProducts}
            onClick={() => actions.handleFieldDisable("supportedProducts")}
          />
        )}
      </Form.Group>
      {publishedStatusMap.get(version.status) && (
        <Form.Group className="d-flex mb-3 align-items-center position-relative">
          <Col xs={4}>
            <Form.Label htmlFor="publishedAt" className="m-0">
              {translate("versions.publishedAt")}
            </Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="datetime-local"
              id="publishedAt"
              name="publishedAt"
              onChange={handleChange}
              disabled={disabledFields.publishedAt}
              value={new Date(values.publishedAt).toISOString().substring(0, 16)}
              className="w-100"
            />
          </Col>
          {hasLockedFields && isSystemAdmin && (
            <EditableFieldButton
              name="publishedAt"
              isDisabled={disabledFields.publishedAt}
              onClick={() => actions.handleFieldDisable("publishedAt")}
            />
          )}
        </Form.Group>
      )}
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Check.Label htmlFor="publishToRWSAppStore">
            {translate("plugins.publishToRWSAppStore")}
          </Form.Check.Label>
        </Col>
        <Switch
          checked={values?.publishToRWSAppStore}
          name="publishToRWSAppStore"
          onChange={(e) => actions.handleSwitchChange(e, "publishToPrivateAppStore")}
        />
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Check.Label htmlFor="publishToPrivateAppStore">
            {translate("plugins.publishToPrivateAppStore")}
          </Form.Check.Label>
        </Col>
        <Switch
          checked={values?.publishToPrivateAppStore}
          name="publishToPrivateAppStore"
          onChange={(e) => actions.handleSwitchChange(e, "publishToRWSAppStore")}
        />
      </Form.Group>
      {touched.initialComment && !!errors.initialComment && (
        <span className="invalid-feedback d-inline">{errors.initialComment}</span>
      )}
    </>
  );
};
