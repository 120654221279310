import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { UserInvitation } from "../../identity/models/UserInvitation";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../../identity/models/ExtendedUser";
import { apiRoutes } from "../../../apiRoutes";

export const useInvite = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0<ExtendedUser>();
  const { translate } = useLocalization();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();
  const accountId = user?.user_metadata?.selectedAccount.id;

  const handleInvite = async (value: UserInvitation, callback: () => Promise<void>) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.userAccounts.create(),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "POST",
      onSuccess: async () => {
        await callback();
        pushNotification({
          message: translate("request.account.inviteUserSuccess", { email: value.email }),
          type: "success"
        });
      },
      onFailure: async (response) => {
        const json = await response.json();
        pushNotification({ ...json, message: translate("request.account.inviteUserFail"), type: "danger" });
      },
      onAny: () => setIsLoading(false)
    });
  };

  const handleReinvite = async (userId: string, email: string) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.userProfiles.invite(userId),
      method: "POST",
      body: JSON.stringify({ accountId }),
      contentType: "application/json",
      onSuccess: () =>
        pushNotification({
          message: translate("request.account.reinviteUserSuccess", { email }),
          type: "success"
        }),
      onFailure: async (response) => {
        const json = await response.json();
        pushNotification({ ...json, message: translate("request.account.reinviteUserFail"), type: "danger" });
      },
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, handleInvite, handleReinvite };
};
