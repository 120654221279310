import { useTheme } from "../../contexts/ThemeContext";

type NavigationTabButtonProps = {
  isSelected: boolean;
  name: string;
  onClick: () => void;
};

export const NavigationTabButton = ({ isSelected, name, onClick }: NavigationTabButtonProps) => {
  const { buttonStyle } = useTheme();
  const activeStyle = isSelected ? buttonStyle : {};

  return (
    <li className="nav-item">
      <h3 className="nav-link cursor-pointer m-0 fs-6" style={activeStyle} onClick={onClick}>
        {name}
      </h3>
    </li>
  );
};
